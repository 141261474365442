<template>
  <nav class="font-wslight flex flex-col text-base md:text-base">
    <div class="bg-white pt-3 pb-2 px-2 lg:px-0 bg-opacity-50 space-x-2 h-14 flex flex-row">
      <div class="flex-none w-3/12 pt-1 flex items-center" :class="(type)?'':['px-2', 'text-white', 'bg-wsblue uppercase']">
           <router-link v-if="this.$route.params.plot" :to="'/home/' + this.$route.params.plot">
            <img v-if="type" class="w-14" :src="`/static/${type}.svg`" :alt="title">
            <div v-else>
              {{title}}
            </div>
          </router-link>
          <router-link v-else to="/">
            <img v-if="type" class="w-14" :src="`/static/${type}.svg`" :alt="title">
            <div v-else>
              {{title}}
            </div>
          </router-link>
      </div>
      <div class="flex-grow flex px-2 pt-1 items-center  text-white bg-wssalmon uppercase">waterschool m4h+</div>
      <div class="flex-none w-12 py-0">
        <router-link to="/about">
          <img class="w-full h-full" src="@/assets/MENU_icon.svg" />
        </router-link>
      </div>
    </div>
    <div class="text-indigo self-end mr-3">
      <router-link v-if="this.$route.params.plot" :to="'/home/' + this.$route.params.plot">
          back
      </router-link>
      <router-link v-else to="/">
         back
      </router-link>
    </div>
  </nav>

</template>

<script>

 
export default {
    name: "NavBar",
    props: ["title",'type'],
    components: {

    },
    mounted() {
     //console.log(this.type)
    //console.log(this.$route.params)
  },
}

</script>

<style scoped>
 
</style>