<template>
  <main>
    <nav-bar
      class="sticky top-0 z-10"
      :title="this.$route.params.plot"
      :type="this.$route.params.caretaker"
    />

    <div class="px-2 z-0 font-wsbook lg:w-3/6 mx-auto">
      <div v-if="$apollo.queries.plotPeopleIds.loading">Loading...</div>
      <div v-if="error">{{ error }}</div>
      <!-- <div>{{plotTags.nodes[0].plots.nodes[0].content}}</div> -->
      <div v-if="plotPeopleContent.caretaker">
        <div class="text-lg font-wsmedium pb-2">
          {{ plotPeopleContent.caretaker.title }}
        </div>
        <div
          class="content content-caretaker content-filter-image font-wsbook text-base leading-5"
          v-html="plotPeopleContent.caretaker.content"
        ></div>
      </div>

      <div v-if="plotPeopleContent.communityMembers">
        <div id="list" class="py-3">
          <a
            class="block underline text-pink"
            v-for="(member, index) in plotPeopleContent.communityMembers.nodes"
            :key="index"
            :href="'#member' + member.databaseId"
          >
           {{member.communityMemberDetails.projectNumber}} {{ member.title }} 
          </a>
        </div>
        <div
          v-for="(member, index) in plotPeopleContent.communityMembers.nodes"
          :key="index"
          :id="'member' + member.databaseId"
        >
          <div>
            <img
              class="w-14 py-2"
              :src="`/static/communitypeople.svg`"
              alt="icon"
            />
          </div>
          <div class="text-xl text-pink font-wsbook uppercase">
            <span>{{ member.title }}</span>
            <span class="float-right text-blue">
              <a href="#list">&#8593;</a>
            </span>
          </div>
          <div class="text-xl text-pink font-wsbook pb-2">
            {{ member.communityMemberDetails.author }}
          </div>
          <div
            class="content content-community font-wsbook text-base leading-5"
            v-html="member.content"
          ></div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { GET_PEOPLE_IDS_PLOT_QUERY } from "../constants/graphql";
import { GET_PEOPLE_CONTENT_PLOT_QUERY } from "../constants/graphql";

export default {
  name: "Plot",
  components: {
    NavBar,
  },
  methods: {},
  computed: {
    
  },
  data() {
    return {
      plotPeopleIds: [],
      plotPeopleContent: {
        caretaker: {
          title: this.$route.params.caretaker.charAt(0).toUpperCase() + this.$route.params.caretaker.slice(1) + " of " + this.$route.params.plot,
          content: "coming soon"
        }
      },
      caretakerId: 0,
      communityMemberIds: [],
      error: null,
    };
  },
  mounted() {
    //console.log(this.plotTags)
    //console.log(this.$route.params)
  },
  apollo: {
    plotPeopleIds: {
      // graphql query
      query: GET_PEOPLE_IDS_PLOT_QUERY,
      variables() {
        // Use vue reactive properties here
        return {
          plot: this.$route.params.plot,
        };
      },
      update(data) {
        return data.plotTags.nodes[0];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    plotPeopleContent: {
      // graphql query
      query: GET_PEOPLE_CONTENT_PLOT_QUERY,
      skip: true,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.caretakerId,
          ids: this.communityMemberIds,
        };
      },
      update(data) {
        //console.log(data)
        return data;
      },
      //  update: data => data.hello,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },
  watch: {
    plotPeopleIds: function () {
      // pick the caretaker from the list
      let caretakers = this.plotPeopleIds.caretakers.nodes;
      caretakers.forEach((caretaker) => {
        if (
          caretaker.caretakerDetails.caretakerType.name.toLowerCase() ===
          this.$route.params.caretaker
        ) {
          this.caretakerId = caretaker.databaseId;
        }
      });

      // pick the community members from the list
      let communityMembers = this.plotPeopleIds.communityMembers.nodes;
      communityMembers.forEach((communityMember) => {
        if (
          communityMember.communityMemberDetails.caretakerType.name.toLowerCase() ===
          this.$route.params.caretaker
        ) {
          this.communityMemberIds.push(communityMember.databaseId);
        }
      });

      if (this.caretakerId != 0) {
        // get their content
        this.$apollo.queries.plotPeopleContent.start();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.content >>> p {
  margin-bottom: 20px;
}

.content >>> iframe {
  width: 100%;
}

.content >>> figcaption {
  padding-top: 3px;
  text-align: right;
  background-color: white;
  font-family: "Favorit Std Light";
}

.content >>> .wp-block-image {
  margin: 10px 0;
}

.content-filter-image >>> .wp-block-image {
  background-color: #ffffff;
  /* display:             flex;
  flex:                1 1 100%; */
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.content-filter-image >>> .wp-block-image img {
  filter: grayscale(100%) contrast(1) blur(0px);
  /* flex:                1 0 100%; */
  height: 100%;
  max-width: 100%;
  /* mix-blend-mode:      multiply; */
  object-fit: cover;
  opacity: 1;
  position: relative;
  width: 100%;
}

.content-filter-image >>> .wp-block-image::before {
  background-color: #001689;
  /* opacity: .5; */
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  /* mix-blend-mode:      screen; */
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.content-filter-image >>> .no-filter {
  background-color: none;
}

.content-filter-image >>> .no-filter img {
  filter: none;
  mix-blend-mode: normal;
}

.content-filter-image >>> .no-filter::before {
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
}

.content-caretaker >>> figcaption {
  
  color: #eaa89a;
  position: absolute;
  width:100%;
}

.content-caretaker >>> .wp-block-image::before {
  background-color: #001689;
  mix-blend-mode: screen;
}

.content-caretaker >>> .wp-block-image {
  background-color: #ffffff;
  overflow: unset;
  margin-bottom: 40px;
}

.content-caretaker >>> .wp-block-image img {
  mix-blend-mode: multiply;
  opacity: 1;
}

.content-community >>> figcaption {
  color: #001689;
}

.content-community >>> .wp-block-image::before {
  background-color: #000000;
  mix-blend-mode: lighten;
}

.content-community >>> .wp-block-image {
  background-color: #efb8a9;
}

.content-community >>> .wp-block-image img {
  mix-blend-mode: luminosity;
  opacity: 0.77;
}





.content-filter-image >>> .podcast {
  background-color: #ffffff;

  /* display:             flex;
  flex:                1 1 100%; */
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.content-filter-image >>> .podcast iframe {
  filter: grayscale(100%) contrast(1) blur(0px);
  /* flex:                1 0 100%; */
  height: 100%;
  max-width: 100%;
  mix-blend-mode:      multiply;
  object-fit: cover;
  opacity: 1;
  position: relative;
  width: 100%;
}

.content-filter-image >>> .podcast::before {
  background-color: #001689;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  mix-blend-mode:      screen;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}
</style>






